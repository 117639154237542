import React from 'react';
import white from '../assets/white.jpeg';

function Image(props) {
    let imageUrl = white;
    if (props.image_id && props.image_id.length > 1) {
        imageUrl = "https://www.artic.edu/iiif/2/" + props.image_id + "/full/843,/0/default.jpg";
    }
    return <img src={imageUrl} className="image" />;
}

export default Image;