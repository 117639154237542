import React from 'react';

function Description(props) {
    return (
        <div className="text">
            <p><i>{props.title}</i> {props.date}
            <br />
            {props.artist_title}</p>
            <br />
            <br />
        </div>
    );
}

export default Description;