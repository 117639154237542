import React, { useState, useEffect } from 'react';
import Image from './Image';
import Description from './Description';

function Artwork(props) {
    const predata = {
        image_id: '',
        title: '',
        artist_title: ''
    }

    const [image, setImage] = useState(predata);
    let pieceUrl = "https://api.artic.edu/api/v1/artworks/" + props.id + "?fields=id,title,image_id,artist_title,date_display";
    useEffect(() => {
        fetch(pieceUrl)
            .then(response => response.json())
            .then(data => {
                if (data.status !== 404) {
                    setImage(data.data);
                } else {
                    console.log("Oops!");
                }
            })
            .then(data => {
                if (data.status !== 404) {
                    const prefetchImage = new Image();
                    let src = "https://www.artic.edu/iiif/2/" + data.data.image_id + "/full/843,/0/default.jpg";
                    prefetchImage.src = src;
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div>
            {image.image_id ? (<Image image_id={image.image_id} />):(<div></div>)}
            {image.image_id ? (<Description title={image.title} date={image.date_display} artist_title={image.artist_title}/>):(<div></div>)}
        </div>
    );
}

export default Artwork;